const first = new Array(
  "Lead",
  "Senior",
  "Direct",
  "Corporate",
  "Dynamic",
  "Future",
  "Product",
  "National",
  "District",
  "Central",
  "Regional",
  "Global",
  "Customer",
  "Senior",
  "Investor",
  "Dynamic",
  "International",
  "Legacy",
  "Forward",
  "Internal",
  "Human",
  "Chief",
  "Principal"
);
const middle = new Array(
  "Solutions",
  "Program",
  "Research",
  "Brand",
  "Security",
  "Marketing",
  "Directives",
  "Implementation",
  "Integration",
  "Functionality",
  "Response",
  "Paradigm",
  "Tactics",
  "Identity",
  "Markets",
  "Group",
  "Division",
  "Applications",
  "Optimization",
  "Operations",
  "Infrastructure",
  "Intranet",
  "Communications",
  "Web",
  "Branding",
  "Quality",
  "Assurance",
  "Mobility",
  "Accounts",
  "Data",
  "Creative",
  "Configuration",
  "Accountability",
  "Interactions",
  "Factors",
  "Usability",
  "Metrics"
);
const last = new Array(
  "Supervisor",
  "Associate",
  "Executive",
  "Liason",
  "Officer",
  "Manager",
  "Engineer",
  "Specialist",
  "Director",
  "Coordinator",
  "Administrator",
  "Architect",
  "Analyst",
  "Designer",
  "Planner",
  "Orchestrator",
  "Technician",
  "Developer",
  "Producer",
  "Consultant",
  "Assistant",
  "Facilitator",
  "Agent",
  "Representative",
  "Strategist"
);

const randomValue = (arr) => arr[Math.floor(Math.random() * arr.length)];

export default () => {
  return `${randomValue(first)} ${randomValue(middle)} ${randomValue(last)}`;
};
