import bullshitTitle from "../modules/bullshitTitle";

document.addEventListener("alpine:init", () => {
  Alpine.data("emailSig", () => ({
    formData: {
      name: "Crescendo Collective",
      email: "accounts",
      cell: "",
      title: "Team Member",
      color: "black",
      fax: false,
      address: "usa",
    },
    colors: {
      black: "#000",
      gray: "#bdbdc0",
      orange: "#ec5e29",
    },
    changeColor() {
      console.log(this.formData.color);
    },
    init() {
      this.formData.title = bullshitTitle();
    },

    async getLogo(color) {
      const pageLocation = window.location.href;
      const baseUrl = pageLocation.substring(
        0,
        pageLocation.lastIndexOf("/") + 1
      );

      const getImage = (path) =>
        new Promise((resolve) => {
          const img = document.createElement("img");
          img.onload = () => resolve(img);
          img.src = `src/img/cc-email-sig-${color}.gif`;
        });

      function getDataUrl(img) {
        // Create canvas
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        // Set width and height
        canvas.width = img.width;
        canvas.height = img.height;
        // Draw the image
        ctx.drawImage(img, 0, 0);
        return canvas.toDataURL("image/gif");
      }

      const img = await getImage(color);
      const base64Img = getDataUrl(img);

      return base64Img;
    },

    copiedMessage: "&nbsp;",
    async copy() {
      const self = this;

      const type = "text/html";
      const blob = new Blob([self.$refs.sig.innerHTML], { type });
      const data = [new ClipboardItem({ [type]: blob })];

      navigator.clipboard.write(data).then(function () {
        self.copiedMessage = "Got it!";
        setTimeout(() => {
          self.copiedMessage = "&nbsp;";
        }, 900);
      });
    },
  }));
});
